<template>
  <v-form @submit.prevent="submitMethod">
    <div class="flex-center chip-container">
      <v-scale-transition class="flex-center">
        <v-chip
          align="center"
          class="my-0 mx-auto"
          :style="{visibility: success ? 'visible' : 'hidden'}"
          v-show="success"
          color="green"
          text-color="white"
          small
        >
          <v-icon small class="mr-2">
            fa-check-circle
          </v-icon>
          Tax Rate Added
        </v-chip>
      </v-scale-transition>
    </div>

    <v-autocomplete
      ref="taxSelect"
      autofocus
      required
      :items="taxRatesForSelect"
      v-model="editedPricing.tax_rate_id"
      label="Tax Rate"
    />
    <div class="flex-center">
      <v-btn
        color="primary"
        outlined
        @click="handleTaxRateButtonClick()"
      >
        <v-icon x-small class="pr-1">
          {{ taxRateButtonIcon }}
        </v-icon>
        {{ taxRateButtonText }}
      </v-btn>
    </div>

    <v-expand-transition>
      <v-form
        class="mt-2 py-4 flex-center flex-column"
        v-show="shouldShowForm"
        transition="fade-transition"
        ref="form"
        v-model="formIsValid"
        lazy-validation
        @submit.prevent="addNewRate"
      >
        <v-slide-y-transition>
          <div v-show="!success" class="flex-center flex-column">
            <v-text-field
              label="Name"
              v-model="editedTaxRate.name"
              :rules="[v => !!v || 'Name is required']"
            />
            <v-text-field
              label="Tax %"
              type="number"
              v-model="editedTaxRate.value"
              :rules="[v => (v && Number(v) >= 0 && Number(v) <= 100 ) || 'Tax Rate must be between 0-100']"
            />
            <v-btn
              class="mx-0 my-auto"
              color="primary"
              :disabled="!formIsValid"
              small
              text
              type="submit"
              ref="addButton"
            >
              Add New Rate
            </v-btn>
          </div>
        </v-slide-y-transition>
      </v-form>
    </v-expand-transition>

    <hidden-submit-button />
  </v-form>
</template>

<script>
import HiddenSubmitButton from '@/components/common/HiddenSubmitButton.vue';

export default {
  name: 'TotalsEditor',

  components: {
    HiddenSubmitButton,
  },

  data() {
    return {
      defaultTaxRate: {
        name: 'Sales Tax',
        value: 10,
        default: 0,
        default2: 0,
        id:-1,
        __typename: 'TaxRate',
      },
      zeroTaxRateForSelect: {
        text: 'No Tax (0%)',
        value: 0,
      },
      editedPricing: {},
      editedTaxRate: {},
      formIsValid: true,
      shouldShowForm: false,
      success: false,
    };
  },

  mounted() {
    this.editedPricing = {
      ...this.pricing,
      tax_rate_id: Number(this.pricing.tax_rate_id),
      tax_rate_id2: Number(this.pricing.tax_rate_id2),
    };
  },

  computed: {
    highestId() {
      return this.taxRates.reduce((highestId, rate) => {
        highestId = Number(rate.id) > highestId ? Number(rate.id) : highestId;
        return highestId;
      }, 0);
    },

    pricing() {
      return this.$store.state.proposals.current.event_cost;
    },

    saveBtnEnabled() {
      return this.pricing.tax_rate_id !== this.editedPricing.tax_rate_id;
    },

    taxRates() {
      return this.$store.state.proposals.current.tax_rates;
    },

    taxRatesForSelect() {
      const taxRatesForSelect = [ { ...this.zeroTaxRateForSelect } ];

      this.taxRates.forEach(rate => {
        taxRatesForSelect.push({
          text: `${rate.name || '[Untitled]'} (${rate.value}%)`,
          value: Number(rate.id),
        });
      });

      return taxRatesForSelect;
    },

    taxRateButtonIcon() {
      return this.shouldShowForm ? 'fa-times' : 'fa-plus';
    },

    taxRateButtonText() {
      return this.shouldShowForm ? 'Close' : 'New Tax Rate';
    },
  },

  watch: {
    'editedPricing.tax_rate_id': function(newId) {
      if (newId === 0) {
        this.editedPricing.tax_rate_value = 0;
      } else {
        this.editedPricing.tax_rate_value = this.taxRates.find(rate => rate.id * 1 === newId * 1).value;
      }
    },

    success(newVal) {
      if (true === newVal) {
        setTimeout(() => this.success = false, 1500);
      }
    },
  },

  methods: {
    addNewRate() {
      if (!this.validate()) {
        return;
      }

      this.editedTaxRate.id = this.highestId + 1;
      this.editedTaxRate.default = this.taxRates.length > 0 ? 0 : 1;

      this.$store.commit('updateField' , { field: 'tax_rates', value: [ ...this.taxRates, this.editedTaxRate ] });
      
      this.success = true;
      this.shouldShowForm = false;
      this.editedPricing.tax_rate_id = this.editedTaxRate.id;
      this.editedTaxRate = { ...this.defaultTaxRate };

      this.$refs.taxSelect.focus();
    },

    handleTaxRateButtonClick() {
      if (this.shouldShowForm) {
        this.shouldShowForm = false;
      } else {
        this.editedTaxRate = { ...this.defaultTaxRate };
        this.shouldShowForm = true;
      }
    },

    save(close) {
      this.$store.commit('updateFields', {
        tax_rate_id: this.editedPricing.tax_rate_id,
        tax_rate_id2: this.editedPricing.tax_rate_id2,
        tax_rate_value: this.editedPricing.tax_rate_value,
        tax_rate_value2: this.editedPricing.tax_rate_value2,
      });

      this.$store.commit('recalculatePricing');
      this.$store.commit('setCurrentChanged', true);

      close();
    },

    submitMethod() {
      if (this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },

    validate () {
      return this.$refs.form.validate();
    },

    reset () {
      return this.$refs.form.reset();
    },

    resetValidation () {
      return this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.chip-container {
  height: 30px;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}
</style>
